import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { SignupButton, Row, Typography, Container, Section, SectionTitle, FeatureGrid, FeatureRows, Article } from "components/ui"
import Image from "components/image"


const BackupNotifications = () => (
  <Layout>
		<SEO refKey="backup-notification" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="lg:grid grid-cols-12 gap-20">
            <div className="lg:col-span-6">
              <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                Instant backup notifications & summary reports.
              </h1>
              <Typography tag="p" className="mt-2">
                Getting the right notification in the right time is critical, especially when it comes to backups.<br/><br/>
                Whether you need to be notified whenever a backup event is sent (completion, failure, success), or if you want to limit the amount of notifications
                sent to you, SimpleBackups allows you to define your notification preference for each backup.<br/><br/>
              </Typography>
              <Typography tag="p" className="mt-2">
                We support : <strong>Email</strong>, <strong>Slack</strong>, <strong>Discord</strong> and <strong>Pushover</strong>.
              </Typography>
              <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Be notified when backups fail"/>
            </div>
            <div className="hidden lg:col-span-6 lg:block">
              <Image filename="simplebackups-notifications.png" alt="SimpleBackups slack, discord and pushover notifications for backup" style={{maxWidth: '700px'}}/>
            </div>
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <FeatureRows items={
            [
              {
                title: "Email, Slack, Discord & Pushover Integration",
                description: (
                  <>
                      <Typography tag="p" className="mt-2">
                        For each backup you can define where you want to be notified and have a different notification per backup.<br/>
                        SimpleBackups supports email, <a href="https://slack.com" target="_blank" rel="noopener noreferrer nofollow">Slack</a>, <a href="https://discord.com/" target="_blank" rel="noopener noreferrer nofollow">Discord</a> and <a href="https://pushover.net/" target="_blank" rel="noopener noreferrer nofollow">Pushover</a> notifications.
                      </Typography>
                      <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Be notified when backups fail"/>
                  </>
                ),
                image: (<Image
                  filename="simplebackups-notifications-screenshot.png" alt="SimpleBackups slack and discord notifications for backup" style={{maxWidth: '850px'}}
                />)
              }]}/>
        </Row>
      </Container>
    </Section>

    <Section color="dark">
      <Container>
        <Row>
          <div className="col-md-12">
            <SectionTitle
              title={(<>How to get started.</>)}
              tag="h2"
              align="left"
            />

            <FeatureGrid cols="3" className="mt-8">
              <Article link="https://docs.simplebackups.com">Our documentation section on notifications.</Article>
              <Article link="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/backup-storage/7mmC9PmjPjx9Kj2Ms6eznr">Get started with cloud storage for backups.</Article>
            </FeatureGrid>
          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)
export const frontmatter = {
  pageMetaData: {
    refKey: "backup-notification",
    title: "Email, Slack, Discord & Pushover Notifications for backups",
    description: "Define when and how to get notified for each server and database backup. SimpleBackups supports email, slack discord, or pushover notifications.",
    author: "simplebackups.com"
  }
}

export default BackupNotifications
